$(document).ready(function () {

  initStickyHeader();
  initMenuScroll();
  initReviewsCarousel();

});

function initStickyHeader() {
  let menu = document.querySelector('.header');
	let origOffsetY = menu.offsetTop;
	function scroll() {
		if ($(window).scrollTop() > origOffsetY) {
			$('.header').addClass('scrolling');
		} else {
			$('.header').removeClass('scrolling');
		}
	}
	scroll();
	document.onscroll = scroll;
}

function initMenuScroll() {
	$('.scroll-init').on('click',function(e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top,
		}, 800);
	});
}

function initReviewsCarousel() {
  let whyWeCarousel = new Swiper ('.reviews__carousel', {
    slidesPerView: 'auto',
    speed: 500,
		autoHeight: true,
    loop: true,
    navigation: {
      nextEl: '.reviews__carousel .carousel__navigation .navigation__next',
      prevEl: '.reviews__carousel .carousel__navigation .navigation__prev',
    },
		pagination: {
	    el: '.reviews__carousel .carousel__pagination',
	    type: 'bullets'
	  }
  });
}
